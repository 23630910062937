import NavBar from '../components/NavBar';
import Card from '../components/Card';
import "./App.css";
import CheckTech from '../components/technologies/CheckTech';
import { useState, useEffect } from 'react';

const PROJECTS = [
  {
    name: "Fellowsguide",
    image: "https://i.imgur.com/wYHFcWC.png",
    fullImage: "https://i.imgur.com/ok4ghnc.png",
    site: "https://www.fellowsguide.com/",
    desc: "A fansite and resource for the game Fellowship, currently being developed by Chief Rebel. Get information about Heroes, Dungeons, and more!",
    techs: ["MongoDB", "NextJS", "React", "Nodejs", "TailwindCSS", "HeroUI", "AWS"]
  },
  {
    name: "Qwizard",
    image: "https://i.imgur.com/fjOdS6o.png",
    fullImage: "https://i.imgur.com/T7AK5AK.png",
    github: "https://github.com/barrientosjesus/qwizard",
    site: "https://qwizard-app-ce9f2a48ce09.herokuapp.com/",
    desc: "A versatile quiz and trivia application to see which of your friends is the best of the best. Do you have what it takes to be a Qwizard?",
    techs: ["MongoDB", "Express", "React", "Nodejs"]
  },
  {
    name: "5e Character Creator",
    image: "https://i.imgur.com/SI2qkZm.png",
    fullImage: "https://i.imgur.com/rsIzBlf.jpg",
    github: "https://github.com/barrientosjesus/5e-character-creator",
    site: "https://dnd5e-character-creator-83a7088f4a41.herokuapp.com",
    desc: "A D&D character creator using barebones SRD material. This was my first time utilizing an API and using heavy client side JavaScript to handle my creation form.",
    techs: ["MongoDB", "Express", "Nodejs"]
  },
  {
    name: "Battle Starship",
    image: "https://i.imgur.com/DDNJ1IL.png",
    fullImage: "https://i.imgur.com/NCMyHqQ.png",
    github: "https://github.com/barrientosjesus/battleship",
    site: "https://barrientosjesus.github.io/battleship/",
    desc: "For my first project at General Assembly, I decided to make Battleship with a Star Wars twist. Fight for the Empire or for the Rebel Alliance, and destroy their fleet first to win!",
    techs: ["JavaScript", "HTML", "CSS"]
  }
];

const MISCTECH = ["Python", "Django", "React"];
const projectTechs = PROJECTS.map(project => project.techs);
const allTechs = [].concat(...projectTechs, MISCTECH);
const uniqueTechs = [...new Set(allTechs)];

export default function App() {
  const [size, setSize] = useState(window.innerWidth <= 1024 ? '50%' : '100%');
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      const newWindowSize = window.innerWidth;
      setWindowSize([newWindowSize, window.innerHeight]);
      setSize(newWindowSize <= 1024 ? '50%' : '100%');
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="App">
      <NavBar />
      <div className="hero min-h-screen" id="aboutme" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1618336753974-aae8e04506aa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80)' }}>
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="max-w-lg">
            <div className='tooltip hello-there-tt' data-tip="">
              <h1 className="mb-5 text-5xl font-bold">Hello there.</h1>
            </div>
            <p className="mb-5 tracking-wide font-medium text-xl">I'm <span className='text-purple-500'>Jesus Barrientos</span>, a Software Developer who loves figuring out problems and coding solutions.
              As a former desktop support specialist and a junior sysadmin, I honed my natural skill for troubleshooting and problem solving. I am now looking to bring those skills to an industry where they will be utilized to their full potential.
            </p>
          </div>
        </div>
      </div>
      <div className="below-hero hero">
        <div className='bg-black/80 h-full w-full px-3 md:p-24'>
          <div className="hero-overlay bg-transparent">
            <div id="projects" className='container mx-auto flex justify-center lg:justify-start'>
              <span className='text-3xl lg:text-7xl font-bold text-white py-16 -skew-x-3'>Projects</span>
            </div>
            <div className='container mx-auto py-2 md:py-16 gap-8 grid ProjectCard items-center'>
              {PROJECTS.map((project, index) => (
                <Card key={index} project={project} id={index} />
              ))}
            </div>
            <div id="technologies" className='container mx-auto flex justify-center lg:justify-start'>
              <span className='text-3xl lg:text-7xl font-bold text-white py-16 -skew-x-3'>Technologies</span>
            </div>
            <div className='container mx-auto flex flex-wrap justify-center justify-items-center text-white py-16 gap-3'>
              {uniqueTechs.map((tech, index) => (
                <CheckTech techName={tech} key={index} em={size} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='lg:hidden py-6 bg-white dark:bg-black/90'>
        <div id="contact" className='container mx-auto flex justify-center py-6'>
          <span className='text-3xl font-bold text-purple-500 dark:text-white'>Contact Info</span>
        </div>
        <div className='flex justify-evenly items-center py-6'>
          <a
            href="https://github.com/barrientosjesus"
            target="_blank"
            rel="noreferrer"
            className="hover:text-purple-500"
          >
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1.75em"
              width="1.75em"
            >
              <path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0138.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z" />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/in/barrientosjesus/"
            target="_blank"
            rel="noreferrer"
            className="hover:text-purple-500"
          >
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1.75em"
              width="1.75em"
            >
              <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1168.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z" />
            </svg>
          </a>
          <a
            href="mailto:jabarri1@gmail.com"
            target="_blank"
            rel="noreferrer"
            className="hover:text-purple-500"
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1.75em"
              width="1.75em"
            >
              <path d="M18.73 5.41l-1.28 1L12 10.46 6.55 6.37l-1.28-1A2 2 0 002 7.05v11.59A1.36 1.36 0 003.36 20h3.19v-7.72L12 16.37l5.45-4.09V20h3.19A1.36 1.36 0 0022 18.64V7.05a2 2 0 00-3.27-1.64z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}
